import { Web3Provider } from '@ethersproject/providers'

const NETWORK_POLLING_INTERVALS = {
  [process.env.CHAIN_ID]: 1_000,
}

export default function getLibrary(provider) {
  let chainId
  if (typeof provider.chainId === 'number') chainId = provider.chainId
  else if (typeof provider.chainId === 'string')
    chainId = parseInt(provider.chainId, 16)
  else chainId = 'any'
  const library = new Web3Provider(provider, chainId)
  library.pollingInterval = 15_000
  library.detectNetwork().then((network) => {
    const networkPollingInterval = NETWORK_POLLING_INTERVALS[network.chainId]
    if (networkPollingInterval) {
      console.debug('Setting polling interval', networkPollingInterval)
      library.pollingInterval = networkPollingInterval
    }
  })
  return library
}
