import React, { useEffect, useState } from 'react'
import {
  createWeb3ReactRoot,
  Web3ReactProvider,
  useWeb3React,
} from '@web3-react/core'
import { useStaticQuery, graphql } from 'gatsby'

import { NetworkContextName } from '~utils/constants'
import getLibrary from '~utils/getLibrary'
import Layout from '~components/Layout'
import injected from '~connectors'
import Main from '~components/Main'
import S from '~components/seo'

const MetamaskProvider = ({ children }) => {
  const {
    active: networkActive,
    error: networkError,
    activate: activateNetwork,
  } = useWeb3React()
  const [loaded, setLoaded] = useState(false)
  useEffect(() => {
    injected
      .isAuthorized()
      .then((isAuthorized) => {
        setLoaded(true)
        if (isAuthorized && !networkActive && !networkError) {
          activateNetwork(injected)
        }
      })
      .catch(() => {
        setLoaded(true)
      })
  }, [activateNetwork, networkActive, networkError])
  if (loaded) {
    return children
  }
  return null
}

const Web3ProviderNetwork = createWeb3ReactRoot(NetworkContextName)

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    {
      contentJson {
        info {
          title
          icon {
            name
            childImageSharp {
              gatsbyImageData(width: 40, quality: 100, placeholder: BLURRED)
            }
          }
        }
        button {
          title
        }
        swordPic {
          name
          childImageSharp {
            gatsbyImageData(width: 532, quality: 100, placeholder: BLURRED)
          }
        }
        lightningPic {
          name
          childImageSharp {
            gatsbyImageData(width: 532, quality: 100, placeholder: BLURRED)
          }
        }
        kingdomPic {
          name
          childImageSharp {
            gatsbyImageData(width: 852, quality: 100, placeholder: BLURRED)
          }
        }
      }
    }
  `)

  return (
    <>
      <S />
      <Web3ReactProvider getLibrary={getLibrary}>
        <Web3ProviderNetwork getLibrary={getLibrary}>
          <MetamaskProvider>
            <Layout>
              <Main {...data.contentJson} />
            </Layout>
          </MetamaskProvider>
        </Web3ProviderNetwork>
      </Web3ReactProvider>
    </>
  )
}

export default IndexPage
