import React from 'react'
import cn from 'classnames'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Button, Container } from 'react-bootstrap'

import * as s from './style.module.scss'
import useClaimToken from '~hooks/useClaimToken'
import useVestingAmount from '~hooks/useVestingAmount'
import useClaimedAmount from '~hooks/useClaimedAmount'
import useAvailableAmount from '~hooks/useAvailableAmount'
import useActiveWeb3React from '~hooks/useActiveWeb3React'

const Main = ({ info, button, swordPic, lightningPic, kingdomPic }) => {
  const [claimToken] = useClaimToken()
  const { account, chainId, library } = useActiveWeb3React()
  const [vestingAmounts] = useVestingAmount()
  const [claimedAmounts] = useClaimedAmount()
  const [availableAmounts] = useAvailableAmount()

  const handleClaimToken = async () => {
    if (account) {
      if (chainId !== 97) {
        try {
          await library?.send('wallet_switchEthereumChain', [
            { chainId: '0x61' },
            account,
          ])
        } catch (e) {
          console.log(e)
        }
      } else {
        const claimTokenResult = await claimToken()
        if (claimTokenResult.message.includes('error')) {
          alert(claimTokenResult.data.message)
        } else if (claimTokenResult && claimTokenResult.blockNumber > 0) {
          alert('success')
        }
      }
    }
  }

  return (
    <main className={s.main}>
      <Container className={s.main_inner}>
        <ul className={s.main_list}>
          {info.map(({ title, icon }) => {
            let value = 0

            if (title === 'Total Tokens Vested') value = vestingAmounts
            else if (title === 'Tokens Claimed') value = claimedAmounts
            else if (title === 'Tokens Remaining')
              value = vestingAmounts - claimedAmounts
            else if (title === 'Tokens Available') value = availableAmounts

            return (
              <li className={s.main_item} key={title}>
                <GatsbyImage
                  image={icon.childImageSharp.gatsbyImageData}
                  alt={icon.name}
                  style={{ display: 'block' }}
                />
                <div className={s.main_item_info}>
                  <p className={s.main_item_title}>{title}</p>
                  <p className={s.main_item_value}>{value}</p>
                </div>
              </li>
            )
          })}
        </ul>
        <Button className={s.main_btn} onClick={handleClaimToken}>
          {button.title}
        </Button>
      </Container>
      <GatsbyImage
        className={cn(s.main_pic, s.sword)}
        image={swordPic.childImageSharp.gatsbyImageData}
        alt={swordPic.name}
        style={{ position: 'absolute', display: 'block' }}
      />
      <GatsbyImage
        className={cn(s.main_pic, s.lightning)}
        image={lightningPic.childImageSharp.gatsbyImageData}
        alt={lightningPic.name}
        style={{ position: 'absolute', display: 'block' }}
      />
      <GatsbyImage
        className={cn(s.main_pic, s.kingdom)}
        image={kingdomPic.childImageSharp.gatsbyImageData}
        alt={kingdomPic.name}
        style={{ position: 'absolute', display: 'block' }}
      />
    </main>
  )
}

export default Main
