import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useWeb3React } from '@web3-react/core'
import { Button, Container } from 'react-bootstrap'
import cn from 'classnames'

import injected from '~connectors'
import insertMiddleEllipsis from '~utils/insertMiddleEllipsis'

import logo from '~img/logo.png'

import * as s from './style.module.scss'

const Header = ({ siteTitle }) => {
  const [metamaskConnectionStatus, setMetamaskConnectionStatus] = useState(null)
  const { active, connector, activate, account, library } = useWeb3React()
  const isMetamask = window.ethereum && window.ethereum.isMetaMask

  const handleConnectMetamask = async () => {
    setMetamaskConnectionStatus('loading')
    try {
      await activate(injected)
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    if (active && connector) {
      try {
        library?.send('wallet_switchEthereumChain', [
          { chainId: '0x61' },
          account,
        ])
      } catch (e) {
        console.log(e)
      }
      setMetamaskConnectionStatus('connected')
    }
  }, [account, connector, active])

  return (
    <Container as="header" className={s.header}>
      <div className={s.header_logo}>
        <img width="126" height="127" src={logo} alt={siteTitle} />
      </div>
      <div className={s.header_heading}>
        <h1 className={s.header_title}>
          <span data-label="Tria TRN">Tria TRN</span>
        </h1>
        <h2 className={s.header_subtitle}>TOKEN</h2>
      </div>
      {!isMetamask ? (
        <Button variant="hollow" className={cn(s.header_btn)}>
          <a
            style={{ textDecoration: 'none' }}
            target="_blank"
            rel="noopener noreferrer"
            href="https://metamask.io/"
          >
            DOWNLOAD METAMASK
          </a>
        </Button>
      ) : (
        <>
          {metamaskConnectionStatus === 'loading' && (
            <Button variant="hollow" className={cn(s.header_btn, s.loading)}>
              CONNECTING
            </Button>
          )}
          {metamaskConnectionStatus === 'connected' && (
            <Button variant="hollow" className={cn(s.header_btn, s.connect)}>
              {account && insertMiddleEllipsis(account, 155)}
            </Button>
          )}
          {!metamaskConnectionStatus && (
            <Button
              variant="hollow"
              className={s.header_btn}
              onClick={handleConnectMetamask}
            >
              CONNECT TO METAMASK
            </Button>
          )}
        </>
      )}
    </Container>
  )
}

Header.defaultProps = {
  siteTitle: '',
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

export default Header
