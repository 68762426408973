import { useCallback } from 'react'

import useContract from './useContract'
import { VESTING_ADDRESS } from '~utils/constants'
import VESTING_ABI from '~constants/abis/vesting.json'

const useClaimToken = () => {
  const contract = useContract(VESTING_ADDRESS, VESTING_ABI)
  const claimToken = useCallback(async () => {
    const tx = await contract
      .claim()
      .then((res) => {
        return res
      })
      .catch((e) => {
        return e
      })
    return tx
  }, [contract])

  return [claimToken]
}

export default useClaimToken
