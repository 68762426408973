import { useEffect, useState } from 'react'

import useContract from './useContract'
import useActiveWeb3React from './useActiveWeb3React'

import { VESTING_ADDRESS } from '~utils/constants'
import VESTING_ABI from '~constants/abis/vesting.json'

const useClaimedAmount = () => {
  const [amount, setAmount] = useState(0)
  const { account, chainId } = useActiveWeb3React()
  const tokenContract = useContract(VESTING_ADDRESS, VESTING_ABI, false)

  const refetchAmount = async () => {
    async function getClaimedAmount(contract) {
      try {
        if (account && chainId) {
          const result = await contract?.claimedAmounts(account)
          return parseFloat(result.toString()) / 10 ** 18
        }
      } catch (error) {
        return 0
      }
      return 0
    }
    const result = await getClaimedAmount(tokenContract)
    setAmount(result)
  }

  useEffect(() => {
    if (account && tokenContract) {
      refetchAmount()
    }
  }, [account, tokenContract])

  return [amount]
}

export default useClaimedAmount
